import React, {Component} from 'react';

class Login extends Component {
  componentDidMount() {
    window.location.reload();
  }

  render() {
    return (
      <div>

      </div>
    );
  }
}

export default Login;